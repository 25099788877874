import { useMatches } from "@remix-run/react";
import { useMemo } from "react";
import type { PageStaticData } from "~/api/get_static_data.server";
import type { FilterProp } from "./screens/search_filter/types";
import type { IMainFilterData, ITag } from "./types";
/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(
  id: string,
  nestedRoute?: string
):
  | {
      //todo update types
      filterData?: Array<FilterProp>;
      mainFilterData?: Array<IMainFilterData>;
      tagsList?: Array<ITag>;
      authors?: Array<{ id: number; name: string }>;
      item?: any;
      themeSession?: any;
      links: any;
      defaultValuesMainForm: any;
      gdprConsent: "all" | "necessary";
    }
  | undefined {
  const matchingRoutes = useMatches();
  // console.log("matchingRoutes", matchingRoutes);
  const route = useMemo(
    () => matchingRoutes.find((route) => route.pathname === id),
    [matchingRoutes, id]
  );
  if (nestedRoute && route?.data && route?.data?.static) {
    const staticData = route?.data?.static?.filter(
      (item: PageStaticData) => item.id === nestedRoute
    );
    // console.log("staticData from matching ", staticData);
    return {
      filterData: route?.data?.filterData,
      mainFilterData: route?.data?.mainFilterData,
      tagsList: route?.data?.tagsList,
      authors: route?.data?.authors,
      defaultValuesMainForm: route?.data?.defaultValuesMainForm,
      item: staticData[0]
        ? staticData[0]
        : {
            id: "",
            title: "",
            subtitle: "",
            isLandingPage: false,
            hasPageIntro: false,
            hasLogo: false,
            body: "",
          },
      themeSession: route?.data.themeSession,
      links: route?.data.links,
      gdprConsent: route?.data?.gdprConsent,
    };
  }
  // console.log("routedata from matching ", route);
  return route?.data;
}
